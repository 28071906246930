import moment from 'moment';
const pathSpliter = (path,index)=>{
     let divs =  path.split('/')
     return divs[index];
}
const authInfoSeter = JSON.parse(sessionStorage.getItem('auth_info'));

const dateTimeFormat  =  'dd/MM/yyyy';
const dateFormat  =  "DD MMM Y";


const accessChecker = (accessName)=>{
      let auth =  JSON.parse(sessionStorage.getItem('auth_info'));
      if(auth.access != undefined || auth.access.length != 0){
           let access = JSON.parse(auth.access)
           return access.indexOf(accessName);
      }else{
            return false
      }
      
}

const convertNumberToWords =  (amountToWord)=>{
      var words = new Array();
      words[0] = '';
      words[1] = 'One';
      words[2] = 'Two';
      words[3] = 'Three';
      words[4] = 'Four';
      words[5] = 'Five';
      words[6] = 'Six';
      words[7] = 'Seven';
      words[8] = 'Eight';
      words[9] = 'Nine';
      words[10] = 'Ten';
      words[11] = 'Eleven';
      words[12] = 'Twelve';
      words[13] = 'Thirteen';
      words[14] = 'Fourteen';
      words[15] = 'Fifteen';
      words[16] = 'Sixteen';
      words[17] = 'Seventeen';
      words[18] = 'Eighteen';
      words[19] = 'Nineteen';
      words[20] = 'Twenty';
      words[30] = 'Thirty';
      words[40] = 'Forty';
      words[50] = 'Fifty';
      words[60] = 'Sixty';
      words[70] = 'Seventy';
      words[80] = 'Eighty';
      words[90] = 'Ninety';
      let amount = amountToWord == null ? '0.00' : amountToWord.toString();
      var atemp = amount.split(".");
      var number = atemp[0].split(",").join("");
      var n_length = number.length;
      var words_string = "";
      if (n_length <= 9) {
          var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
          var received_n_array = new Array();
          for (var i = 0; i < n_length; i++) {
              received_n_array[i] = number.substr(i, 1);
          }
          for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
              n_array[i] = received_n_array[j];
          }
          for (var i = 0, j = 1; i < 9; i++, j++) {
              if (i == 0 || i == 2 || i == 4 || i == 7) {
                  if (n_array[i] == 1) {
                      n_array[j] = 10 + parseInt(n_array[j]);
                      n_array[i] = 0;
                  }
              }
          }
          let value = "";
          for (var i = 0; i < 9; i++) {
              if (i == 0 || i == 2 || i == 4 || i == 7) {
                  value = n_array[i] * 10;
              } else {
                  value = n_array[i];
              }
              if (value != 0) {
                  words_string += words[value] + " ";
              }
              if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                  words_string += "Crores ";
              }
              if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                  words_string += "Lakhs ";
              }
              if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                  words_string += "Thousand ";
              }
              if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                  words_string += "Hundred and ";
              } else if (i == 6 && value != 0) {
                  words_string += "Hundred ";
              }
          }
          words_string = words_string.split("  ").join(" ");
      }
      return words_string + ` Only (${authInfoSeter.currency})`;
  }
  
const checkAuthBranchWare = (branchID)=>{
      if(authInfoSeter.userInfo.user_branch_id==branchID ){
            return true
      }else{
            return false
      }
}

const checkIntNum = (number)=>{
      return !Number.isInteger(parseFloat(number))?true:false
}

let getDateTimeFromISODT = (isoString)=>{
      
      var date = moment(isoString);
      var dateComponent = date.utc().format('Y-MM-DD');
      var timeComponent = date.utc().format('hh:mm');
      return dateComponent+'T'+timeComponent
      
}

const currentDateTime = ()=>{
      return moment().toISOString()
}


const currentYear = ()=>{
    return moment().year();
}

const currentMonth = ()=>{
    return moment().format('MMMM');
}


const currentDateStartTime = ()=>{
      return moment().startOf('day').toISOString()
}
const currentDateEndTime = ()=>{
      return moment().endOf('day').toISOString()
}



const _p = (prom)=>{
    return new Promise(function(resolve,reject){
        let error = null;
        let data = null;
        prom
        .then(d=>{
            data = d.data;
            resolve([error,data]);
        })
        .catch(e=>{
            error=e;
            resolve([error,data]);
        });
        
    })
}

const  sleep = (delay = 0) => {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }


  const nextTotalDaysOfMonth = (totalMonth,created_date)=>{
    // Get current date
const currentDate = new Date(created_date);

let month = 0

// Loop through the next 5 months and get the total number of days for each month
for (let i = 0; i < totalMonth; i++) {
  // Get the month and year for the next iteration
  const nextMonth = currentDate.getMonth() + i + 1;
  const nextYear = currentDate.getFullYear();

  // Handle the case where the next month is in the next year
  if (nextMonth > 11) {
    nextMonth = 0;
    nextYear = nextYear + 1;
  }

  // Get the total number of days for the next month
  const totalDays = new Date(nextYear, nextMonth + 1, 0).getDate();

  // Log the result
  console.log(`Total days for ${nextMonth}/${nextYear}: ${totalDays}`);


  month = month + parseFloat(totalDays)
}

return month;

  }


  const nextTotalWeekOfMonth = (totalMonth,created_date)=>{

    let month = 0

    // Get current date
    const currentDate = new Date(created_date);

// Loop through the next 5 months and get the total number of weeks for each month
for (let i = 0; i < 5; i++) {
  // Get the month and year for the next iteration
  const nextMonth = currentDate.getMonth() + i + 1;
  const nextYear = currentDate.getFullYear();

  // Handle the case where the next month is in the next year
  if (nextMonth > 11) {
    nextMonth = 0;
    nextYear = nextYear + 1;
  }

  // Get the first and last day of the next month
  const firstDay = new Date(nextYear, nextMonth, 1);
  const lastDay = new Date(nextYear, nextMonth + 1, 0);

  // Calculate the total number of weeks for the next month
  const totalWeeks = Math.ceil((lastDay.getDate() - firstDay.getDate() + 1 + firstDay.getDay()) / 7);

  // Log the result

  month = month + parseFloat(totalWeeks)

}


console.log(month, 'week' )

return month;

  }
export {pathSpliter,_p,sleep,authInfoSeter,convertNumberToWords,accessChecker,checkAuthBranchWare,
    dateTimeFormat,dateFormat,checkIntNum,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime,
    currentYear,currentMonth,nextTotalDaysOfMonth,nextTotalWeekOfMonth}
